import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import HttpBackend from 'i18next-http-backend'
import { currentLocale, defaultLocale, supportedLocales } from 'constants/locale'
import Env, { isDevelopment } from 'helpers/environment'

const LOCAL_URL = window.location.origin
const locale = supportedLocales.includes(currentLocale) === true ? currentLocale : defaultLocale
const translationSource = locale === 'en' && isDevelopment ? LOCAL_URL : Env.PUBLIC_CDN_URL

const options = {
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
    prefix: '%{',
    suffix: '}',
  },
  backend: {
    loadPath: `${translationSource}/partners-translations/${locale}.json`,
  },
}

i18next.use(HttpBackend).use(initReactI18next).init(options)

export default i18next
